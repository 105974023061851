.maqola__full{
    width: 80% !important;
    display: flex;
    justify-content: center;
}
.maqola__item{
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
    margin: 10px 0px;
    border-radius: 10px;
}
.maqola__item img{
    width: 100%;
    height: 300px;
    transition: 0.8s;
}
.maqola__item:hover img{
    transition: 0.8s;
    transform: scale(1.1);
}
.maqola__item img{
    width: 100%;
}
.maqola__full__info{
    width: 70%;
    height: 160px;
    padding: 10px;
    font-size: 18px;
    position: absolute;
    opacity: 70%;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    color: black; 
}
.maqola__text{
    position: absolute;
    top:25%;
    font-size: 18px;
    color: black;
}
.maqola__text:hover{
    color: #FEAD27;
    cursor: pointer;
}
.maqola__data{
    position: absolute;
    bottom: 20px;
}
.maqola__type{
    position: absolute;
    color: #053D33;
}
.article__title__info{
    width: 80% !important;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}
.article__title{
    font-size: 20px;
    font-weight: bold;
}
.article__btn{
    outline: none;
    border: none;
    background-color: #014E46;
    color: #FFF;
    padding: 3px 12px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
}
