.banber__full01{
    background-color: white;
    /* padding: 10px 0px; */
    margin: 15px 0px;
}
.banner__full{
    width: 90% !important;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
}
.img__banner{
    width:100%;
    object-fit: cover;
}
.banner__02 img{
    width: 390px;
    /* height: 550px; */
    transition: 1s;
}
.banner__02{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    border-radius: 10px;
}
.banner__01{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
}
.btn_open{
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: black;
    z-index: 100;
}
.banner__01 img{
    width: 390px;
    /* height: 550px; */
    border-radius: 5px;
    transition: 1s;
}
.banner__01 img:hover{
    /* transform: scale(1.1); */
    transition: 0.8s;
}
.banner__02 img{
    border-radius: 5px;
    transition: 0.8s;
}
.banner__02 img:hover{
    /* transform: scale(1.1); */
    transition: 0.8s;
}
.banner__text {
    width: 100%;
    line-height: 30px;
    font-family: 900;
    font-weight: bold;
    font-size: 30px;
}
.banner__text_main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main__btn{
    background-color: #053D33;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    border-radius: 10px;
    outline: none;
    border: none;
}
.main__btn02{
    background-color: #053D33;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    border-radius: 10px;
    outline: none;
    border: none;   
}
.link__obuna{
    text-decoration: none;
    z-index: 1000;
}
.main__btn:hover{
  opacity: 90%;
  /* transform: scale(1.01); */
}
.btn_read{
    width: 200px;
    margin: 0 auto;
    padding-top: 30px;
}
/* media */
@media(max-width:1500px){
    .banner__01 img{
        width: 320px;
        height: 500px;
        border-radius: 5px;
        transition: 1s;
    }
    .banner__02 img{
        width: 320px;
        height: 500px;
        border-radius: 5px;
        transition: 1s;
    }
}
@media(max-width:1300px){
    .banner__01 img{
        width: 290px;
        height: 450px;
        border-radius: 5px;
        transition: 1s;
    }
    .banner__02 img{
        width: 290px;
        height: 450px;
        border-radius: 5px;
        transition: 1s;
    }
}
@media(max-width:560px){
    .banner__01 img{
        width: 400px;
    }
    .banner__02 img{
        width: 400px;
    }
    .banner__text_main{
        margin-left: 50px;
        width: 60%;
    }
}
@media(min-width:930px){
    .banner__01 img{
        margin-left: 60px;
    }
}
@media(max-width:450px){
    .banner__01 img{
        width: 280px;
    }
    .banner__02 img{
        width: 320px;
    }
    .main__btn02{
       width: 250px;
    }
}
@media(max-width:380px){
    .banner__01 img{
        width: 250px;
    }
    .banner__02 img{
        width: 250px;
    }
}