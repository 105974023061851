.mapouter{
    width: 80%;
    height: 400px;
    margin: 100px auto;
    background-color: #053D32;
    border-radius: 10px;
    max-width: 1280px;
}
.gmap_iframe{
    width: 95%;
    margin-left: 2.5%;
    height: 400px;
    border-radius: 10px;
    transform: translateY(-50px);
}
@media(max-width:900px){
    .testclass{
        display: none;
    }
}
.Mobile_bottom_menu{
    position: fixed;
    bottom: 0;
    background-color: #053D32;
    color: white;
}
.Mobile_menu{
    display: flex;
}