.new__full{
    width: 100% !important;
}
img{
    width: 100%;
}
.new__title{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.new__text{
  font-size: 20px;
  font-weight: bold;
  margin: 0 20px;
  padding: 5px 15px;
  background-color: #014E46;
  color: #FFF;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: 0.7s;
}
.new__text:hover{
background-color: #FEAD27;
color: #FFF;
transition: 0.7s;
}
.All__new__btn{
    outline: none;
    border: none;
    background-color: #014E46;
    color: #FFF;
    padding: 7px 20px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.8s;
}
.All__new__btn:hover{
    background-color:#FEAD27 ;
    transition: 0.8s;
}
.MuiBox-root{
    z-index:-100 !important;
}
.new__img{
    width: 300px;
    height: 200px;
    width: 100%;
    object-fit: cover;
}
.new__card{
    margin: 10px 0px;
    transition: 0.8s;
    padding: 5px 10px;
}
.new__card:hover{
    box-shadow: 0px 0px 10px 2px grey;
    transition: 0.8s;
    cursor: pointer;
    border-radius: 10px;

}
.news__select_menu{
    max-width: 1280px;
    margin: 0 auto;
}
.categoryTitlename{
    font-weight: 600;
    color: #014E46;
}
.new__btn{
    border: none;
    background-color: #014E46;
    color: #FFF;
    border-radius: 5px;
    margin: 10px 5px;
    transition: 0.8s;
    padding: 5px 10px;
}
.new__btn:hover{
    background-color: #FEAD27;
    transition: 0.8s;
    cursor: pointer;
}
.new__data{
    color: grey;
}
.new__img_div{
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;

}
.titlenews{
    text-decoration: none;
    color: black;
}
.new__img_div img{
    transition: 1.2s;
}
.new__img_div img:hover{
 transform: scale(1.1);
 transition: 1.2s;
}
.news__item__menu{
    width: 100%;
    display: flex;
    justify-content: center;
}
.back__imges{
    width: 100% !important;
    background-image: url("../../../../public/images/xxx_bg_xxx.jpg");
    padding: 40px 0px;
    margin: 50px 0px;
}
.mobile__none__menu{
    display: none;
}
.select__menu{
    margin-left: 25%;
    margin: 25px 25%;
}
.css-1aquho2-MuiTabs-indicator{
    z-index: -1000;
}
.new__btn{
    z-index: -1000;
}
@media(max-width:980px){
    .select__menu{
        margin-left: 18%;
        width: 100%;
    }  
}
@media(max-width:770px){
    .select__menu{
        margin-left: 5%;
    } 
}
@media(max-width:570px){
    .select__menu{
        margin-left: 0%;
    }    
}

