.full__page{
    width: 80%;
    margin: 0 auto;
    border-radius: 30px;
    margin-top: 30px;
    border-radius: 30px;
}
.slider-container {
    /* --swiffy-slider-item-gap-totalwidth: calc(var(--swiffy-slider-item-gap) * (var(--swiffy-slider-item-count) - 1)); */
    /* --swiffy-slider-item-width: calc((100% - var(--swiffy-slider-item-reveal) - var(--swiffy-slider-item-gap-totalwidth)) / var(--swiffy-slider-item-count)); */
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    display: grid;
    align-items: center;
    grid-auto-rows: 300px !important;
    grid-auto-columns: var(--swiffy-slider-item-width);
    /* grid-auto-flow: column; */
    /* grid-gap: var(--swiffy-slider-item-gap); */
    /* list-style: none; */
    /* margin: 0; */
    /* padding: 0; */
    /* position: relative; */
}
.carus-img{
    width: 100%;
}
.slider-indicators-round .slider-indicators>*{
    width: 0.8rem !important;
    height: 0.8rem !important;
}
.carus_class_images{
    width: 100%;
    /* height: 450px; */
}
.carus__images{
    border-radius: 30px !important;
    /* height: 500px; */
}
.carus__full__main{
    width: 80%;
    margin: 30px auto;
    z-index: -1000 !important;
}
.carus__images{
    border-radius: 10px !important;
}
.carus__item{
    border-radius: 10px !important;
}
@media(max-width:950px){
    .carus__images{
        width: 100%;
        /* height: 380px !important; */
    }
}
@media(max-width:780px){
    .carus__images{
        width: 100%;
        /* height: 310px !important; */
    }
}
@media(max-width:570px){
    .carus__images{
        width: 100%;
        /* height: 250px !important; */
    }
}
@media(max-width:480px){
    .carus__images{
        width: 100%;
        /* height: 190px !important; */
    }
}


.SwiperCarus{
    width: 80%;
    /* height: 600px; */
    /* border-radius: 10px; */
    max-width: 1280px;
}
.SwiperCarusImg{
    width: 1000px;
    /* height: 500px; */
    /* border-radius: 10px; */
    margin-left: 100px;
}
@media(max-width:1460px){
    .SwiperCarus{
        width: 80%;
        /* height: 450px; */
        border-radius: 10px;
        max-width: 1280px;
    }
    .SwiperCarusImg{
        width: 800px;
        /* height: 420px; */
        border-radius: 10px;
        margin-left: 100px;
    }
}
@media(max-width:1150px){
    .SwiperCarus{
        width: 80%;
        /* height: 350px; */
        border-radius: 10px;
        max-width: 1280px;
    }
    .SwiperCarusImg{
        width: 600px;
        /* height: 350px; */
        border-radius: 10px;
        margin-left: 100px;
    }
}
@media(max-width:920px){
    .SwiperCarus{
        width: 80%;
        /* height: 280px; */
        border-radius: 10px;
        max-width: 1280px;
    }
    .SwiperCarusImg{
        width: 450px;
        /* height: 280px; */
        border-radius: 10px;
        margin-left: 100px;
    }
}
@media(max-width:720px){
    .SwiperCarus{
        width: 80%;
        /* height: 250px; */
        border-radius: 10px;
        max-width: 1280px;
    }
    .SwiperCarusImg{
        width: 400px;
        /* height: 250px; */
        border-radius: 10px;
        margin-left: 45px;
    }
}
@media(max-width:550px){
    .SwiperCarus{
        width: 80%;
        /* height: 200px; */
        border-radius: 10px;
        max-width: 1280px;
    }
    .SwiperCarusImg{
        width: 350px;
        /* height: 200px; */
        border-radius: 10px;
        margin-left: 30px;
    }
}
@media(max-width:455px){
    .SwiperCarus{
        width: 80%;
        /* height: 200px; */
        border-radius: 10px;
        max-width: 1280px;
    }
    .SwiperCarusImg{
        width: 300px;
        /* height: 190px; */
        border-radius: 10px;
        margin-left: 20px;
    }
}