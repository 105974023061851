
.full__video{
    width: 80% !important;
    margin: 0 auto;
}
.slider-nav.slider-nav-next::after{
    background-color: black !important;
}
.slider-nav::after{
    background-color: black;
}
.Youtobevideo{
    width: 100%;
    height: 220px;
}
@media(max-width:1400px){
    .Youtobevideo{
        width: 100%;
        height: 200px;
    }
}
@media(max-width:1200px){
    .Youtobevideo{
        width: 100%;
        height: 180px;
    }
}
@media(max-width:770px){
    .Youtobevideo{
        width: 100%;
        height: 250px;
    }
}
@media(max-width:515px){
    .Youtobevideo{
        width: 100%;
        height: 200px;
    }
}
@media(max-width:470px){
    .Youtobevideo{
        width: 100%;
        height: 200px;
    }
}
@media(max-width:430px){
    .Youtobevideo{
        width: 100%;
        height: 200px;
    }
}
.media_title{
    font-size: 24px;
    font-weight: 600;
}
.slider_option{
    padding: 10px;
    box-shadow: 0px 0px 15px 5px  rgb(217, 217, 217);
}