.pages__teatr{
    width: 400px !important;
}
.last__news{
    width: 400px;
    /* margin-left: 19px; */
    border: 1px solid rgb(214, 212, 212);
    border-radius: 10px;
}
.lest_new_img{
    border-radius: 10px;
}
.last__new_data__corner{
    font-size: 14px;
}
.lest_new_img{
    width: 120px;
    height: 80px;
}
.lower__menu__item{
    margin: 20px 0px;
}
.teatr_page_title{
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    margin: 0 5px;
    color: black;
    transition: 0.2s;
}
.teatr_page_title:hover{
    color: rgb(76, 76, 255);
    cursor: pointer;
    transition: 0.2s;
}
.icon{
    font-size: 16px;
    margin-bottom: 3px;
    margin-right: 3px;
}
@media(max-width:430px){
    .last__news{
        width: 380px;
    }
    .pages__teatr{
        width: 380px !important;
    }
}
@media(max-width:390px){
    .last__news{
        width: 350px;
    }
    .pages__teatr{
        width: 350px !important;
    }
    .lastnewsfull{
        padding-left: 20px !important;
    }
}
