.JournalSearch{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}
.JournalSearchinp{
    width: 400px;
    padding: 7px;
    padding-left: 30px;
    outline: none;
    border: 1px solid grey;
    border-radius: 5px;
}
@media(max-width:550px){
    .JournalSearchinp{
        width: 300px;
        padding: 7px;
        padding-left: 30px;
        outline: none;
        border: 1px solid grey;
        border-radius: 5px;
    }
}
.readSearchicon{
 font-size: 25px;
 transform: translateX(27px);
}
.readCard{
position: relative;
transition: 1s;
}
.link{
    text-decoration: none;
}
.readjournalimg{
    width: 100%;
    height: 350px;
    object-fit: cover;
}
@media(max-width:770px){
    .readjournalimg{
        width: 100%;
        height: 520px;
        object-fit: cover;
    }  
}
.blackScreen{
    display: none;
    background-color: black;
    opacity: 50%;
    position: absolute;
    width: 100%;
    height: 100%;

}
.readCard:hover .blackScreen{
    display: block;
    cursor: pointer;
    transition: 1s;
}



/* #AllJournal */
.Pagepadding{
    padding: 50px 0px;
}
.alljournalimg{
    width: 100%;
    height: 420px;
    object-fit: cover;
}
@media(max-width:770px){
    .alljournalimg{
        width: 100%;
        height: 700px;
        object-fit: cover;
    }
}